<template>
  <!--遮罩层-->
  <transition name="mask_transition">
    <div v-if="visible" class="by_mask" @click="closePopup()"></div>
  </transition>
</template>

<script>
export default {
    name:'BYmask',
    props:{
        visible:{
            type:Boolean,
            default:false,
        },//是否显示
    },
    data() {
        return {
          // visible:
        };
    },
    created() {},
    methods: {
      closePopup(){
        this.$emit('closePopup')      
      }
    },
};
</script>
<style scoped>
.by_mask{
    overflow: hidden; 
    z-index: 10; 
    width: 100%; 
    height: 100vh; 
    position: fixed; 
    top: 0px; 
    left: 0px; 
    background:rgba(0, 0, 0, 0.4);
}
.mask_transition-enter-active, .mask_transition-leave-active {
  transition: 0.3s;
}
.mask_transition-enter, .mask_transition-leave-to {
  opacity:0;
}
</style>