<template>
    <div>
        <!--登录弹窗-->
        <div v-if="login_popup==1" class="login_popup">
            <div class="login_title">欢迎，登录</div>
            <div style="display:flex;flex-direction:column;height:160px;align-items:center;justify-content:center">
              <div style="display:flex;">
                <el-input v-model="login.mailbox" size="large"  placeholder="请输入邮箱" style="width:140px;margin-right:5px;"></el-input>
                <el-select size="large" v-model="login.mailbox_type" placeholder="请选择" style="width:120px">
                <el-option
                    v-for="item in mailbox_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </div>
                <el-input v-model="login.password" size="large"  placeholder="请输入密码" style="width:265px;margin-top:20px;"></el-input>
            </div>
            <div style="display:flex;justify-content:center;">
              <div class="submit_btn" style="display:inline-block;font-size:18px;margin-right:50px;background-color:#be67cf" @click="login_popup=2">注册</div>
              <div class="submit_btn" style="display:inline-block;font-size:18px;" @click="userLogin()">登录</div>
            </div>
            <div style="text-align:center;margin-top:16px;font-size:14px;">还没有账号 ? 先简单注册一下(＃°Д°)</div>
        </div>
        <!--注册弹窗-->
        <div v-if="login_popup==2" class="popup_body">
        <!-- 上传用户头像(非必传)-->
          <div class="login_title">欢迎，注册</div>
          <div style="display:flex;padding-top:30px;">
            <div style="width:460px;display:flex;justify-content:center;">
              <el-upload
                class="avatar-uploader"
                :action="upload_img"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="register.img" :src="register.img" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div>
              <div style="display:flex;">
                <el-input  size="large" v-model="register.mailbox"  placeholder="请输入邮箱" style="width:140px"></el-input>
                <el-select size="large" v-model="register.mailbox_type" placeholder="请选择" style="width:120px">
                <el-option
                    v-for="item in mailbox_type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </div>
              <el-input v-model="register.name"  size="large"  placeholder="请输入用户名" style="width:260px;margin-top:10px;"></el-input>
              <el-input v-model="register.password" type="password" size="large"  placeholder="请输入密码" style="width:260px;margin-top:10px;margin-bottom:10px;"></el-input>
              <el-input v-model="register.password2" type="password" size="large"  placeholder="请确认密码" style="width:260px"></el-input>
            </div>
          </div>
          <div style="display:flex;">
            <div style="font-size:13px;margin-left:38px;margin-top:10px;margin-top:-20px;">
              <div style="margin-left:18px;">请上传用户头像</div>
              <div>(不传则使用默认头像)</div>
            </div>
            <div style="display:flex;margin-left:60px;margin-top:20px;">
               <div class="submit_btn" style="width:100px;display:inline-block;font-size:18px;margin-right:25px;" @click="login_popup=1">返回登录</div>
              <div class="submit_btn" style="width:100px;display:inline-block;font-size:18px;background-color:#be67cf" @click="userRegister()">完成注册</div>
            </div>
            <!-- <div class="submit_btn"  @click="submit()" style="margin-top:10px;margin-left:130px;">完成注册</div> -->
          </div>
        </div>
        <!--遮罩层组件-->
        <BYmask :visible="login_popup!=0" @closePopup="closePopup"></BYmask>
    </div>
</template>

<script>
import BYmask from "@/components/BYmask/BYmask";//遮罩层
export default {
    components: {
      BYmask
    },
    data() {
        return {
            upload_img:this.$upload_img,//图片上传地址
            login_popup:0,//弹窗类型 0隐藏 1注册 2登录
            mailbox_type:[
              {id:1,name:'@qq.com'},
              {id:2,name:'@163.com'},
              {id:3,name:'@126.com'},
              {id:4,name:'@139.com'},
              {id:5,name:'@sohu.com'},
              {id:6,name:'@aliyun.com'},
              {id:7,name:'@189.cn'},
              ],//用户邮箱类型
            login:{
              mailbox:'',
              mailbox_type:'',
              password:'',
            },//登录请求体
            register:{
              name:'',
              mailbox:'',
              mailbox_type:'',
              password:'',
              password2:'',
              img:'',
              ip:'',
              address:'',
            },//注册请求体
            imageUrl:'',
        };
    },
    mounted(){
      this.getUserCip();//获取ip
    },
    methods: {
        //获取ip
        getUserCip() {
          //获取用户ip
          try{
              const cip = returnCitySN;
              this.register.ip = cip.cip;
              this.register.address= cip.cname;
          }catch{}

        },
        handleAvatarSuccess(res, file) {
          console.log(res)
          this.register.img = this.$api + res.url.file;
          console.log(this.register.img)
        },
        beforeAvatarUpload(file) {
          const isJPG = file.type === 'image/jpeg';
          const isLt2M = file.size / 1024 / 1024 < 2;

          if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
          }
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
          }
          return isJPG && isLt2M;
        },
        //打开弹窗
        openPopup(){
            this.login_popup = 1
            this.login.mailbox_type = this.mailbox_type[0].name; 
            this.register.register = this.mailbox_type[0].name; 
        },
        closePopup(){
            this.login_popup = 0
        },
        //确认登录
        userLogin(){
          if(this.login.mailbox==''||this.login.password==''){
            this.$message.warning('邮箱或密码不能为空')
            return false;
          }
          this.$axios.post(this.$url.userLogin,{
            mailbox:this.login.mailbox + this.login.mailbox_type,
            password:this.login.password,
          }).then(res=>{
            if(res.data.status==200){//登录成功
              let user_info = {
                token:res.data.token,
                img:res.data.img,
                name:res.data.name,
              }
              window.localStorage.setItem('user_info',JSON.stringify(user_info))
              this.closePopup();
              this.$message.success('登录成功！');
              this.$emit('ifLogin2');
            }else{//登录失败
              this.$message.warning(res.data.msg)
            }
          })
        },
        //用户注册
        userRegister(){
          if(this.register.password != this.register.password2){
            this.$message.warning('两次输入密码不一致')
            return false;
          }
          this.$axios.post(this.$url.userRegister,{
            name:this.register.name,
            mailbox:this.register.mailbox + this.register.mailbox_type,
            password:this.register.password,
            img:this.register.img,
            ip:this.register.ip,
            address:this.register.address
          }).then(res=>{
            if(res.data.code!=200){
              this.$message.warning(res.data.msg);
              return false;
            }
            //token放入缓存
            let user_info = {
              img:res.data.img,
              name:res.data.name,
              token:res.data.token
            }
            window.localStorage.setItem('user_info',JSON.stringify(user_info))
            this.closePopup();
            this.$message.success('注册成功！')
            this.$emit('ifLogin2');
          })
        }
    },
};
</script>
<style scoped>
.submit_btn{
  background-color: rgb(18, 172, 172);
  height:30px;
  width:70px;
  text-align: center;
  line-height:30px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 5px;
}
.login_popup{
  position: fixed;
  height:360px;
  width:560px;
  margin-top: -180px;
  margin-left:-260px;
  top:50%;
  left:50%;
  background-color: #FFFFFF;
  border-radius:20px;
  overflow: hidden;
  z-index: 100;
}
.login_title{
  height:100px;
  background-image: url('~@/assets/img/loginbg.png');
  background-size: 100% 100%;
  color: #FFFFFF;
  text-align: center;
  line-height:100px;
  font-size:22px;
  font-weight:800;
}
.popup_body{
  position: fixed;
  height:420px;
  width:560px;
  margin-top: -180px;
  margin-left:-260px;
  top:50%;
  left:50%;
  background-color: #FFFFFF;
  border-radius:20px;
  overflow: hidden;
  z-index: 100;
}

</style>
<style>
/* 上传图片样式 */
/* 上传图片样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>