<template>
    <!--留言组件-->
    <div>
        <div style="background-color:#f0f2f7;">
          <div style="height:50px;"></div>
          <div style="display:flex;justify-content:space-between;margin:0 30px">
            <div style="display:flex;align-items:center;margin-bottom:10px;">
                <div style="font-size:18px;font-weight:700;margin-right:20px;">{{page_type==1?'添加留言':'添加评论'}}</div>
                <div v-if="type==3||type==4" class="reply_div">
                  <div>回复&nbsp;:&nbsp;{{reply_name}}</div>
                  <div class="delete_icon" @click="deleteReply()">×</div>
                </div>
            </div>
            <div v-if="user_login==false" style="display:flex;">
              <div style="margin-right:20px;">留言方式 : 游客</div>
              <div style="display:flex;">
                <div style="color:rgb(18, 172, 172);cursor: pointer;" @click="openLogin()">登录</div>
                <div>即可使用专属头像</div>
              </div>
            </div>
          </div>
          <div style="margin:0 30px;"> 
            <el-input type="textarea" v-model="content" :autosize="{ minRows: 5, maxRows: 5}" placeholder="填写留言"></el-input>
            <div style="display:flex;align-items:center;margin-top:6px;">
                <!--上传图片-->
                <div>
                  <div style="font-size:14px;margin:8px 0;">添加图片( 最多可添加五张 )</div>
                  <el-upload
                    ref="upload"
                    :action="upload_img"
                    list-type="picture-card"
                    :on-success="handleAvatarSuccess"
                    :on-remove="handleRemove"
                    :limit="5"
                    >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
            </div>
            <div style="margin-top:20px;display:flex;">
                <!--未登录-->
                <div v-if="user_login==false"> 
                  <el-input v-model="user_name" size="small"  placeholder="请填写昵称"  style="width:230px;margin-right:2vw"></el-input>
                  <el-input v-model="user_mailbox" size="small"  placeholder="请填写邮箱" style="width:230px;margin-right:2vw;"></el-input>
                </div>
                <!--已登录-->
                <div v-if="user_login==true" style="margin-right:40px;display:flex;align-items:center;">
                  <div>当前用户 : </div>
                  <div style="cursor:pointer;">
                    <el-popover>
                      <div style="display:flex;justify-content:center;">
                        <div @click="quitLogin()" class="quitBtn">退出登录</div>
                      </div>
                      <div slot="reference" >
                        <div style="display:flex;align-items:center;">
                          <div v-if="user_info.img">
                              <img :src="user_info.img" style="height:32px;width:32px;border-radius:50%;margin-left:20px;margin-right:10px;">
                          </div>
                          <div v-else style="margin-left:20px;margin-right:10px;display:flex;align-items:center;">
                              <vue-letter-avatar :name='user_info.name' size='30' :rounded="true"/>
                          </div>
                          <div>{{user_info.name}}</div>
                        </div>
                      </div>
                    </el-popover>
                  </div>

                </div>
                <div class="submit_btn" style="display:inline-block;font-size:18px;" @click="submit()">提交</div>
                <Vcode :show="isShow" @success="onSuccess" @close="onClose"/>
            </div>
          </div>
          <div style="height:30px;"></div>
        </div>
        <!--评论列表-->
        <div style="background-color:#FFFFFF;">
        <div v-for="(item,index) in comment_list" :key="index" style="padding-top:20px;">
          <div style="min-height:100px;display:flex;">
        	  <div style="width:50px;"></div>
        	      <div style="background-color:#f2f2f2;width:92%;display:flex;padding-top:10px;border-radius:5px;justify-content:space-between;">
                    <div style="display:flex;max-width:800px;">
                        <img v-if="item.user_type==1&&item.user_img" :src="item.user_img" style="height:60px;width:60px;margin-left:-30px;border-radius:5px;">
                        <div v-else style="margin-left:-30px;border-radius:5px;overflow:hidden;height:60px;width:60px;">
                            <vue-letter-avatar :name="item.user_name?item.user_name:'Bai'" size='60' :rounded="false"/>
                        </div>
        	            <div>
        	              <div style="margin-left:20px;font-weight:800;font-size:18px;">{{item.user_name}}</div>
        	              <div style="margin-left:20px;font-size:14px;margin-top:10px;padding-right:20px;padding-bottom:10px;">{{item.content}}</div>
                        <div style="display:flex;margin-left:20px;">
                          <div  v-for="(item3,index3) in item.img_list" :key="index3" >
                            <img :src="item3" style="height:50px;width:50px;margin-right:10px;cursor:pointer;" @click="enlargeImg(item.img_list,index3)">
                          </div>
                        </div>
        	              <div style="margin-left:20px;color:rgb(138, 135, 135);font-size:12px;margin-bottom:10px;">{{item.create_time | timeFilter}}</div>
        	            </div>
                    </div>
                    <div style="height:100%;display:flex;align-items:flex-end;">
                        <div style="margin-bottom:10px;margin-right:30px;font-size:14px;cursor:pointer;" @click="translate(item._id,item.user_name,item._id)">回复</div>
                    </div>
        	      </div>
            	</div>
              <!--二级回复-->
              <div v-for="(item2,index2) in item.reply_info" :key="index2" class="comment_body">
                <div style="display:flex;">
                    <div style="display:flex;margin-left:10px;">
                        <img v-if="item2.user_type==1&&item2.user_img" :src="item2.user_img" style="height:50px;width:50px;border-radius:6px;">
                        <div v-else style="border-radius:5px;overflow:hidden;height:60px;width:60px;">
                            <vue-letter-avatar :name='item2.user_name' size='60' :rounded="false"/>
                        </div>
                    </div>
                    <div style="margin-left:16px;font-size:14px;margin-top:4px;">
                        <div>{{item2.user_name}}  回复 {{item2.reply_name}}</div>
                        <div style="margin-top:10px;margin-bottom:10px;font-size:13px;">{{item2.content}}</div>
                        <div style="display:flex;">
                          <div v-for="(item4,index4) in item2.img_list" :key="index4" >
                            <img :src="item4" style="height:50px;width:50px;cursor:pointer;" @click="enlargeImg(item2.img_list,index4)">
                          </div>
                        </div>
                        <div style="color:rgb(138, 135, 135);font-size:12px;margin-top:10px;">{{item2.create_time | timeFilter}}</div>
                    </div>
                </div>
                <div style="display:flex;align-items:flex-end;">
                    <div style="margin-right:20px;font-size:14px;cursor:pointer;" @click="translate(item._id,item2.user_name,item2._id)">回复</div>
                </div>
              </div>
          </div>
           <div style="height:70px;"></div>
          </div>
          <!--登录组件-->
          <Login ref="child" @ifLogin2="ifLogin2"></Login>

          <!--查看大图弹窗-->
		      <div v-if="enlarge_img==true" @click="enlarge_img=false" >
		      	<div style="position: fixed;bottom: 0;left: 0;width: 100%;height: 100%;opacity:0.8;background-color: #000000;z-index:20;" ></div>
		      	<div style="position: fixed;bottom: 0;left: 0;width: 100%;height: 100%;;z-index:21;display:flex;align-items:center;justify-content:center;">
		      		<img src="~@/assets/icon/houtui.png" style="transform:rotate(180deg);height:120px;position:fixed;top:50%-60px;left:16vw;cursor: pointer;"  @click.stop="changeLeft()">
		      		<img :src="select_img" style="max-height:660px;max-width:900px;"  @click.stop >
		      		<img src="~@/assets/icon/houtui.png" style="height:120px;position:fixed;top:50%-60px;right:16vw;cursor: pointer;" @click.stop="changeRight()">
		      	</div>
		      </div>

    </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";//滑动验证码
import Login from './../components/Login.vue';//登录

export default {
    name:'Comment',
    components: {
        Vcode,
        Login
    },
    props:{
      comment_top:{//距离顶部高度
        type:Number,
        default:0
      },
      user_login:{//用户是否登录
        type:Number,
        default:0
      },
      user_info:{//用户是否登录
        type:Object,
        default:{}
      },
      page_type:{//页面类型 1留言板 2文章
        type:Number,
        default:0
      },
      article_id:{//文章id
        type:String,
        default:''
      },
      comment_list:{//评论列表
        type:Array,
        default:[]
      }
    },
    
    data() {
      return {
          isShow: false,//验证码弹窗
          upload_img:this.$upload_img,//图片上传地址
          type:this.page_type,//1留言板 2文章 3留言板回复 4文章回复
          comment_id:'',//回复id(只有在回复状态时才有)
          index:0,//选中图片队列
          select_img:'',//放大图片

          content:'',//回复内容
          img_list:[],//用户上传图片列表
          item_img:[],//查看大图中的图片列表
          user_mailbox:'',//用户邮箱
          user_name:'',

          reply_name:'',//回复人姓名
          content_id:'',//回复哪条的id
          enlarge_img:false,//查看大图变量
        };
    },
    methods: {
      //移除图片方法
      handleRemove(file, fileList) {
        this.img_list = [];
        let every_img = '';
        for(let i=0;i<fileList.length;i++){
            every_img =  this.$api + fileList[i].response.url.file;
            this.img_list.push(every_img);
        }
      },
      //上传图片方法
      handleAvatarSuccess(res, file) {
        let every_img = this.$api + res.url.file;
        this.img_list.push(every_img)
      },
      //回复评论
      translate(id,name,content_id){
        //父组件高度变化
        this.$emit('heightChange');
        this.reply_name = name;
        //type状态改变
        if(this.page_type==1){
          this.type = 3
        }else{
          this.type = 4
        }
        this.comment_id = id;
        this.content_id = content_id;
      },
      ifLogin2(){
        this.$emit("ifLogin");
      },
      //删除回复
      deleteReply(){
        this.reply_name = '';
        if(this.page_type==3){
          this.type = 1
        }else{
          this.type = 2
        }
        this.comment_id = '';
        this.content_id = ''
      },
      onSuccess(msg) {
        this.isShow = false; // 通过验证后，需要手动关闭模态框
        this.addComment();
      },
      onClose() {
        this.isShow = false;
      },
      openLogin(){
        this.$refs.child.openPopup();
      },
      //提交按钮
      submit(){
        if(this.user_login==0&&/^\s*$/g.test(this.user_name)){//若未登录
          this.$message.warning('请填写昵称')
          return false
		    }
        if(this.user_login==0&&!/^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(this.user_mailbox)){
          this.$message.warning('请填写正确邮箱')
          return false
        }
        if(/^\s*$/g.test(this.content)){
            this.$message.warning('请填写评论内容')
            return false
		    } 
        this.isShow = true;
      },
      //添加回复
      addComment(){
        let comment_data = {};
        if(this.user_login==1){//若登录
          comment_data = {
            user_type:1,
            token:this.user_info.token,
            type:this.type,
            img_list:this.img_list,
            content:this.content,
            comment_id:this.comment_id,
            content_id:this.content_id,
            reply_name:this.reply_name,
          }
        }else{//未登录
          comment_data = {
            user_type:0,
            user_name:this.user_name,
            user_mailbox:this.user_mailbox,
            type:this.type,
            img_list:this.img_list,
            content:this.content,
            comment_id:this.comment_id,
            content_id:this.content_id,
            reply_name:this.reply_name,
          }
        }
        //判断是回复留言 还是回复文章
        if(this.type==2){//文章评论带上id
          comment_data.article_id = this.article_id
        }else if(this.type==3||this.type==4){//回复带上回复id
          comment_data.comment_id = this.comment_id;
        }
        this.$axios.post(this.$url.addComment,{...comment_data,type:this.type}).then(res=>{
          this.$message.warning('发布成功');
          //清除原数据
          this.comment_id = '';
          this.content_id = '';
          this.content ='';
          this.user_mailbox = '',
          this.user_name = '',
          this.reply_name = '';
          this.$refs.upload.clearFiles();//清除图片列表
          this.img_list = [];
          if(this.page_type==3){
            this.type = 1
          }else{
            this.type = 2
          }
          //请求评论列表
          this.$emit("getComment");
        })
      },
      //退出登录
      quitLogin(){
        this.$emit("quitLogin");
      },
      //查看大图方法,接收两个参数,1图片列表,2当前点击图片
		  enlargeImg(item_img,index){
        console.log(item_img,index)
		  	this.item_img = item_img;
		  	this.index = index;
		  	this.select_img = item_img[index];
		  	this.enlarge_img = true;
		  },
		  //向左查看图片
		  changeLeft(){
		  	//如果是第一张
		  	if(this.index==0){
		  		this.$message.warning('这已经是第一张了')
		  	}else{
		  		this.index = this.index*1-1
		  		this.select_img = this.item_img[this.index];
		  	}
		  },
		  //向右查看图片
		  changeRight(){
		  	//如果是最后一张
		  	if(this.index==this.item_img.length-1){
		  		this.$message.warning('没有更多图片啦')
		  	}else{
		  		this.index = this.index*1+1
		  		this.select_img = this.item_img[this.index];
		  	}
		  },
    },
};
</script>
<style scoped>
.comment_body{
    display: flex;
    background-color: #f2f2f2;
    padding:8px;
    border-radius: 6px;
    margin-top:5px;
    display: flex;
    justify-content: space-between;
    margin-left:80px;
    margin-right:30px;
}
.submit_btn{
  background-color: rgb(18, 172, 172);
  height:30px;
  width:70px;
  text-align: center;
  line-height:30px;
  cursor: pointer;
  color: #ffffff;
  border-radius: 5px;
}
.reply_div{
  background-color: rgb(18, 172, 172);
  color:#ffffff;
  display:flex;
  align-items: center;
  padding:2px 16px;
  border-radius: 10px;
}
.delete_icon{
  margin-left:20px;
  font-size:22px;
  cursor: pointer;
}
.delete_icon:hover{
  color: rgb(223, 179, 99);
}
.quitBtn{
  /* color:#3c1bf8; */
  font-size:18px;
  cursor: pointer;
}
.quitBtn:hover{
  color:#a433d1;
}
</style>